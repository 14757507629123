<template >
    <div class="row">
        <div class="col-12">
        <div class="main" v-if="isShowMainModal">
            <div class="mainheading text-left">
                {{title}}
            </div>
            <hr />
            <p class="help">Double click words to check dictionary</p>
            <p class="transcript text-left">Transcript:</p>
            <!-- <p class="paragraph" v-on:dblclick="getSelectionText()">{{templatePara}}</p> -->
            <p v-html="templatePara" class="paragraph" v-on:dblclick="getSelectionText()"/>
            <hr/>
         <b-button variant="outline-primary" class="mt-3" left @click="$bvModal.hide('dictionery-Modal')">Close Me</b-button>
        </div>
        <div class="child" v-else>
            <div class="header">
                {{selectedText}}
                <br/>                
            </div>
            <div class="pronounce">
                {{pronounce}}
                <img src="@/assets/images/audio-player.png" @click="playsound()"/>
            </div>
            <hr />
            <b-row class="text-left mb-2">
                    <div class="col-12 mb-2" >
                       <div class="row heading" >
                        <div class="col-6">Definitions</div>
                        <div class="col-6">Examples</div>
                       </div>
                    </div>
                <div class="col-12" v-for="(record, index) in resultData" :key="index">
                       <div class="row" v-for="(item, i) in record.definitions" :key="i">
                        <div class="col-6 mb-2">[{{record.partOfSpeech}}] {{item.definition}}</div>
                        <div class="col-6"> {{item.example}}</div>
                        <!-- <div class="col-12" v-for="(syn, j) in item.synonyms" :key="j">
                            <span>{{syn}} ,</span>
                        </div> -->
                       </div>
                </div>
            </b-row>
            <hr/>
            <div class="leftbtn">
                <b-button variant="outline-primary" class="mr-2 pl-4 pr-4 " @click="showMain()">Go Back</b-button>
            </div>
            <div class="rightbtn">
                <b-button variant="outline-primary" class="mr-2 pl-4 pr-4" @click="saveVocab()">Save Vocabulary</b-button>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { mapActions,mapGetters } from 'vuex'


export default {
    props: {
        templatePara: {
                type: String
                },
        title:{
            type: String
        }
    },
    data(){
        return {
            isShowMainModal: true,
            currentUserId:0,
            selectedText:'',
            resultData:null,
            pronounce:'',
            audio:null
        }
    },
    methods:{
        ...mapActions({
            questionservice: 'speakmodule/getDictionery',
            saveAnswer: 'speakmodule/saveAnswer'
        }),
        ...mapGetters({
            getQuestionList: 'speakmodule/questions',
            answerResponse: 'speakmodule/AnswerResponse'
        }),
        saveVocab(){
            const userData={
                    user_id:this.currentUserId,
                    vocab_desc:this.selectedText,
                    url:'userVocab/saveVocab'
                }
                //console.log(userData)
                 this.saveAnswer(userData).then(()=>{
                        //console.log(this.answerResponse().data)
                        this.$alert(this.answerResponse().message)
                 });            
        },
        showMain(){
            this.isShowMainModal = true;
        },
        getSelectionText() 
        {
            this.selectedText = "";
            var vm = this;
            if (window.getSelection) {
                this.selectedText = window.getSelection().toString();
            } else if (document.selection && document.selection.type != "Control") {
                this.selectedText = document.selection.createRange().text;
            }
            //console.log(this.selectedText)
            
            this.questionservice('https://api.dictionaryapi.dev/api/v2/entries/en_US/'+this.selectedText).then(()=>{
                var getQuestionList = this.getQuestionList();
                //console.log(getQuestionList);
                
                //console.log(getQuestionList[0].phonetics[0].audio)
                vm.resultData = getQuestionList[0].meanings
                vm.pronounce = getQuestionList[0].phonetics[0].text
                vm.audio = getQuestionList[0].phonetics[0].audio
                this.playsound()
                vm.isShowMainModal = false;
                //console.log(vm.resultData)
            });
        },
        playsound(){
            var audioElement = document.createElement('audio');
            audioElement.setAttribute('src', this.audio);
            audioElement.play()
        }
    },
    beforeMount(){
        var userdata = this.$ls.get('user')
        this.currentUserId= userdata.user_id
    }
}
</script>

<style scoped>
.mainheading
{
    font-size: 14px;
font-weight: 500;
}
    .content{
        position: relative;
        background-color: #fff;
        background-clip: padding-box;
        border: 0;
        border-radius: 4px;
        box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
        pointer-events: auto;
    }
    .help{
        font-weight: 400;
        color: #999;
        margin-bottom: 8px;
        font-size: .8rem;
        font-size: 1rem;
    }
    .transcript{
        margin-right: 5px;
margin-bottom: 5px;
display: block;
line-height: 32px;
font-size: 16px;
color: #000;
text-align: left !important;
    }
    h2{
        color:#12d3bf;
        font-size:32px;
    }
    .header{
        text-align: left;
font-size: 32px;
color: #12d3bf;
display: inline-block;
margin-right: 10px;
    }
    .heading{
        margin-bottom: 16px;
        color: #999;
        font-size: 16px;
    }
    p{ 
        text-align: justify;
        line-height: 2;
        word-spacing: 3px;
        
    }
    p.paragraph
    {
cursor: pointer;
    }
    .pronounce{
        text-align: left;
        display: inline-block;
    }
    .horizontal-divider{
        display: block;
        clear: both;
        width: 100%;
        min-width: 100%;
        height: 1px;
        margin: 24px 0;
    }
    img{
        cursor: pointer;
    }
    .leftbtn{
        float: left;
    }
    .rightbtn{
        float: right;
    }
    ::-moz-selection { /* Code for Firefox */
  color: red;
  background: yellow;
}

::selection {
  color: red;
  background: yellow;
}
</style>