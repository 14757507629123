<template>
    <div class="row mt-5">
        <div class="col-sx-12 col-sm-12 col-md-6 col-lg-4">
            <video-embed :params="{showinfo: 0}" :src="url"></video-embed>
            
        </div>
        <div class="col-sx-12 col-sm-12 col-md-6 col-lg-7">
            <h5 class="mt-2 mb-2"><strong>{{title}}</strong></h5>
            <p class="para mb-2">
                {{ desc }}
            </p>
        </div>
    </div>
</template>
<script>
// import LazyYoutube from 'vue-lazytube'
export default {
    // components: {
    //     LazyYoutube,
    // },
    props:['showOverLap'],
    data(){
        return{
            url:"https://www.youtube.com/watch?v=bT39ZsiCDfE",
            title:"PTE READING MODULE | Fill in the Blanks Tips Tricks and Strategies | PTE Classes.Online",
            desc:"The PTE Reading test checks your ability to read and write in the English language. It is the second section of the test and will take approximately 30-40 minutes. It includes 5 tasks in total. The reading materials for this section are all authentic texts about a variety of academic subjects, including humanities, natural sciences, and social sciences. "
            
        }
    }
}
</script>
<style scoped>
div.row.mt-5{
    margin-top: 3rem!important;
}
</style>